// Verkhivker.js
import React, { useEffect } from 'react';
import { Book, Building2, GraduationCap, FlaskConical, BookOpen } from 'lucide-react';
import verkhivkerImage from './Headshots/Verkhivker.jpg';

const Verkhivker = () => {
  // Increase the font size for this page by 2 points when mounted
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    // Increase font size by roughly 2pt using a calc expression
    htmlEl.style.fontSize = 'calc(100% + 2pt)';

    return () => {
      // Restore the original font size on unmount
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  // Container classes for larger screen real estate
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="flex flex-col md:flex-row items-center gap-8">
            {/* Profile Image */}
            <div className="shrink-0">
              <img
                src={verkhivkerImage}
                alt="Dr. Gennady Verkhivker"
                className="rounded-lg shadow-lg w-48 h-48 md:w-64 md:h-64 object-cover"
              />
            </div>
            {/* Text Content */}
            <div className="text-center md:text-left">
              <h1 className="text-4xl font-bold mb-4">Dr. Gennady Verkhivker</h1>
              <p className="text-xl">
                Professor of Computational Biosciences and Translational Medicine at Chapman University
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Main Bio Column */}
            <div className="md:col-span-2 space-y-8">
              <div>
                <h2 className="text-2xl font-bold mb-4">Current Positions</h2>
                <div className="space-y-4">
                  <div className="flex items-start">
                    <Building2 className="text-[#4169E1] mt-1 mr-3" size={20} />
                    <div>
                      <p className="font-medium">Professor of Computational Biosciences and Translational Medicine</p>
                      <p className="text-gray-600">Chapman University</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Building2 className="text-[#4169E1] mt-1 mr-3" size={20} />
                    <div>
                      <p className="font-medium">Adjunct Professor of Pharmacology</p>
                      <p className="text-gray-600">Department of Pharmacology, UC San Diego</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h2 className="text-2xl font-bold mb-4">Biography</h2>
                <div className="prose max-w-none">
                  <p className="mb-4">
                    Dr. Verkhivker received his PhD in Physical Chemistry from Moscow University and completed a postdoctoral 
                    fellowship in computational biophysics from University of Illinois at Chicago in 1992.
                  </p>
                  <p className="mb-4">
                    As one of the founding scientists at Agouron Pharmaceuticals Inc. in the early 1990s, he played a leading 
                    role in establishing computer-aided structure-based design technology. From 1993 to 2006, he held various 
                    research and management positions at Agouron Pharmaceuticals, Warner-Lambert, and Pfizer Global Research 
                    and Development, La Jolla Laboratories.
                  </p>
                  <p className="mb-4">
                    Since 2002, he has served as Adjunct Professor of Pharmacology at the Skaggs School of Pharmacy and 
                    Pharmaceutical Sciences, UC San Diego. In 2006, he joined the School of Pharmacy and Center for 
                    Bioinformatics at The University of Kansas as a Full Professor of Pharmaceutical Chemistry and Bioinformatics.
                  </p>
                  <p>
                    In 2011, Dr. Verkhivker assumed the position of Full Professor of Computational Biosciences & Translational 
                    Medicine at Schmid College of Science & Technology and Professor at the Department of Biomedical and 
                    Pharmaceutical Sciences at Chapman University School of Pharmacy.
                  </p>
                </div>
              </div>

              <div>
                <h2 className="text-2xl font-bold mb-4">Research Focus</h2>
                <p className="text-gray-600 mb-4">
                  Dr. Verkhivker's recent research activities concentrate on:
                </p>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <FlaskConical className="text-[#4169E1] mt-1 mr-3" size={20} />
                    <span>Computational systems biology</span>
                  </li>
                  <li className="flex items-start">
                    <FlaskConical className="text-[#4169E1] mt-1 mr-3" size={20} />
                    <span>Translational bioinformatics</span>
                  </li>
                  <li className="flex items-start">
                    <FlaskConical className="text-[#4169E1] mt-1 mr-3" size={20} />
                    <span>
                      Systems pharmacology with focus on integration of computational and experimental systems biology approaches in translational research
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Sidebar */}
            <div className="space-y-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 flex items-center">
                  <Book className="text-[#4169E1] mr-2" size={24} />
                  Publications
                </h3>
                <p className="text-gray-600 mb-4">
                  Authored more than 250 peer-reviewed publications in the fields of:
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Translational bioinformatics</li>
                  <li>• Computational biophysics</li>
                  <li>• Structure-based drug discovery</li>
                  <li>• Targeted and personalized anti-cancer agents</li>
                </ul>
                <div className="space-y-3 mt-4">
                  <a href="/publications" className="text-[#4169E1] hover:text-blue-700 inline-flex items-center">
                    <Book className="mr-2" size={16} />
                    View Publications
                  </a>
                  <a 
                    href="https://scholar.google.co.uk/citations?view_op=list_works&hl=en&hl=en&user=L6nSSNIAAAAJ" 
                    className="text-[#4169E1] hover:text-blue-700 inline-flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BookOpen className="mr-2" size={16} />
                    Google Scholar Profile
                  </a>
                </div>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-bold mb-4 flex items-center">
                  <GraduationCap className="text-[#4169E1] mr-2" size={24} />
                  Education
                </h3>
                <div className="space-y-3">
                  <div>
                    <p className="font-medium">PhD in Physical Chemistry</p>
                    <p className="text-gray-600">Moscow University</p>
                  </div>
                  <div>
                    <p className="font-medium">Postdoctoral Fellowship</p>
                    <p className="text-gray-600">Computational Biophysics</p>
                    <p className="text-gray-600">University of Illinois at Chicago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Verkhivker;