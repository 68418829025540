import React, { useEffect } from 'react';
import labCoat from './Images/MolecularChaperones/Picture1.png';
import moleculeCollage from './Images/MolecularChaperones/Picture2.png';

const MolecularChaperones = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-800 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-4">
              Translational Bioinformatics and Computational Structural Biology of Molecular Chaperones Hsp70 and Hsp90
            </h1>
            <p className="text-xl italic mb-4">
              Decoding the Dynamics, Binding, and Allosteric Regulation of Hsp70 and Hsp90 for Target-Centric Drug Discovery
            </p>
          </div>
        </div>
      </section>

      {/* Optional Visual Highlights */}
      <section className="w-full py-12 bg-gray-100">
        <div className={containerClasses}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="h-64 overflow-hidden rounded-lg shadow-lg">
              <img 
                src={moleculeCollage}
                alt="Molecular structures collage" 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-64 overflow-hidden rounded-lg shadow-lg">
              <img 
                src={labCoat}
                alt="Laboratory research" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Overview Section */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Overview</h2>
          <p className="text-gray-600 mb-4">
            Molecular chaperones such as Hsp70 and Hsp90 play a central role in cellular proteostasis, regulating protein folding, preventing aggregation, and orchestrating signal transduction pathways. Dysregulation of these chaperones is implicated in numerous diseases, including cancer, neurodegenerative disorders, and infectious diseases. The complexity of their allosteric regulation—where distant sites modulate function—makes them both fascinating and challenging therapeutic targets.
          </p>
          <p className="text-gray-600 mb-4">
            Our lab focuses on integrating structural bioinformatics, computational simulations, network modeling, and AI predictions to unravel the dynamic mechanisms of Hsp70 and Hsp90. By combining advanced computational approaches with experimental validation, we aim to decode their allosteric regulatory networks, identify cryptic binding sites, and design selective allosteric modulators. This research bridges fundamental biology with translational applications, advancing our understanding of chaperone function and enabling the discovery of next-generation therapeutics.
          </p>
          <p className="text-gray-600">
            The wealth of structural and functional data on Hsp90 provides an ideal platform for developing integrative computational and experimental workflows. Our interdisciplinary approach leverages perturbation‐based simulations, multiscale network models, NMR experiments, and generative AI to probe and characterize allosteric mechanisms at atomic resolution. These efforts are guided by key objectives aimed at transforming our understanding of Hsp90 allostery and accelerating drug discovery.
          </p>
        </div>
      </section>

      {/* Key Research Themes Section */}
      <section className="w-full py-12 bg-gray-50">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-8">Key Research Themes</h2>

          {/* Theme 1 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">1. Structural Bioinformatics of Hsp70 and Hsp90</h3>
            <p className="text-gray-600 mb-4">
              Structural bioinformatics provides a foundation for understanding the molecular architecture and functional diversity of Hsp70 and Hsp90. Our lab employs integrative approaches to:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Analyze sequence-structure-function relationships:</span> Identify conserved motifs, domains, and post-translational modifications that regulate chaperone function.
              </li>
              <li>
                <span className="font-bold">Reconstruct conformational ensembles:</span> Use homology modeling, cryo-EM data integration, and AlphaFold/ESMFold predictions to generate accurate structural models of Hsp70 and Hsp90 in different functional states.
              </li>
              <li>
                <span className="font-bold">Map interaction networks:</span> Characterize chaperone-client interactions and identify key residues involved in substrate recognition and binding.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These insights guide the identification of cryptic allosteric sites and inform the design of selective modulators.
            </p>
          </div>

          {/* Theme 2 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">2. Computational Simulations of Dynamics and Binding</h3>
            <p className="text-gray-600 mb-4">
              Understanding the dynamic behavior of Hsp70 and Hsp90 is essential for deciphering their functional mechanisms. We employ molecular dynamics (MD) simulations and enhanced sampling techniques to:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Explore conformational landscapes:</span> Map free energy surfaces to identify metastable states and transition pathways during ATP hydrolysis, client binding, and release.
              </li>
              <li>
                <span className="font-bold">Characterize binding mechanisms:</span> Investigate how substrates, co-chaperones, and inhibitors interact with Hsp70 and Hsp90 using docking, free energy calculations, and hybrid QM/MM simulations.
              </li>
              <li>
                <span className="font-bold">Uncover allosteric communication:</span> Analyze long-range signal propagation within chaperone domains and across multi-protein complexes.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These studies provide a mechanistic understanding of chaperone function and guide the optimization of lead compounds targeting specific conformations or interactions.
            </p>
          </div>

          {/* Theme 3 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              3. Development of Perturbation-Based Simulation Approaches and Dynamic Multiscale Network Models
            </h3>
            <p className="text-gray-600 mb-4">
              To dissect the fundamental principles of allosteric regulation in Hsp90, we develop and apply innovative computational tools:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Perturbation-Based Simulations:</span> Use MD simulations and enhanced sampling techniques to model conformational landscapes and transition pathways under various perturbations (e.g., mutations, ligand binding).
              </li>
              <li>
                <span className="font-bold">Dynamic Multiscale Networks:</span> Apply graph theory and statistical mechanics to map residue-residue interactions and identify allosteric hotspots that mediate long-range communication.
              </li>
              <li>
                <span className="font-bold">Mutational Profiling:</span> Perform in silico mutagenesis studies to predict how specific mutations alter allosteric interactions and signaling pathways.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These methods enable the identification of druggable allosteric sites and provide mechanistic insights into Hsp90 regulation.
            </p>
          </div>

          {/* Theme 4 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              4. Integration of Computational Approaches with NMR Chemical Shift Experiments
            </h3>
            <p className="text-gray-600 mb-4">
              NMR spectroscopy offers unparalleled insights into protein dynamics and allosteric regulation. We integrate computational models with NMR chemical shift experiments to:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Interrogate Allosteric Functions:</span> Use NMR data to validate computational predictions of allosteric states and interaction networks.
              </li>
              <li>
                <span className="font-bold">Identify Allosteric Networks:</span> Combine NMR-derived chemical shifts with network-centric models to map residue-level contributions to allosteric communication.
              </li>
              <li>
                <span className="font-bold">Refine Predictive Models:</span> Iteratively refine computational workflows using experimental feedback, ensuring accuracy and biological relevance.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: This integration enhances our ability to predict allosteric mechanisms and validate computational hypotheses experimentally.
            </p>
          </div>

          {/* Theme 5 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              5. Integrative Computational and Experimental Studies of Allosteric Regulatory Switches
            </h3>
            <p className="text-gray-600 mb-4">
              Allosteric regulatory switches govern the functional transitions of Hsp90 between different conformational states. We use integrative approaches to study these switches:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Structural Characterization:</span> Employ NMR analysis and biochemical profiling to elucidate the structural basis of allosteric regulation.
              </li>
              <li>
                <span className="font-bold">Functional Validation:</span> Conduct biochemical assays to confirm the impact of allosteric modulators on Hsp90 activity.
              </li>
              <li>
                <span className="font-bold">Workflow Development:</span> Establish robust workflows for characterizing the structure and dynamics of Hsp90 proteins across multiple scales.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These studies provide a comprehensive understanding of Hsp90’s regulatory mechanisms, guiding the design of selective inhibitors.
            </p>
          </div>

          {/* Theme 6 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              6. Network Modeling of Allosteric Regulation of Hsp70 and Hsp90
            </h3>
            <p className="text-gray-600 mb-4">
              Allostery governs the regulation of Hsp70 and Hsp90 activity through distant sites, offering unique opportunities for therapeutic intervention. We use network science to:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Model residue-residue interactions:</span> Apply graph theory to map communication pathways and identify key nodes in allosteric networks.
              </li>
              <li>
                <span className="font-bold">Quantify signal propagation:</span> Use statistical mechanics to measure the strength and directionality of allosteric signals across chaperone domains.
              </li>
              <li>
                <span className="font-bold">Identify cryptic binding sites:</span> Leverage network analysis to discover transient pockets suitable for allosteric modulation.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These tools enable a systems-level understanding of allostery, guiding the design of highly selective inhibitors and activators.
            </p>
          </div>

          {/* Theme 7 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              7. AI Predictions for Target-Centric Allosteric Drug Discovery
            </h3>
            <p className="text-gray-600 mb-4">
              Artificial intelligence is transforming our ability to predict complex biological phenomena and accelerate drug discovery. Our lab develops and applies AI methods to:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Predict allosteric landscapes:</span> Train deep learning models to reconstruct free energy profiles and identify druggable allosteric sites from limited experimental data.
              </li>
              <li>
                <span className="font-bold">Generate novel ligands:</span> Use generative adversarial networks (GANs) and variational autoencoders (VAEs) to design molecules tailored to specific allosteric pockets.
              </li>
              <li>
                <span className="font-bold">Optimize binding affinity:</span> Refine generated ligands using reinforcement learning and docking simulations to enhance selectivity and potency.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: AI-driven predictions enable rapid exploration of chemical space and accelerate the discovery of next-generation allosteric modulators.
            </p>
          </div>

          {/* Theme 8 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              8. Target-Centric Drug Discovery for Hsp70 and Hsp90
            </h3>
            <p className="text-gray-600 mb-4">
              Traditional inhibitors often target the ATP-binding site of Hsp70 and Hsp90, leading to poor selectivity and off-target effects. Our lab focuses on designing allosteric modulators that target unique structural features of these chaperones:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Fragment-based drug discovery:</span> Combine fragment docking with MD simulations to identify promising starting points for inhibitor design.
              </li>
              <li>
                <span className="font-bold">Structure-based optimization:</span> Iteratively refine inhibitor designs guided by detailed mechanistic insights from simulations and AI predictions.
              </li>
              <li>
                <span className="font-bold">Multi-objective design:</span> Balance properties such as potency, synthetic feasibility, and pharmacokinetics during ligand generation.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These efforts contribute to the development of safer and more effective therapeutics for cancer, neurodegeneration, and infectious diseases.
            </p>
          </div>

          {/* Theme 9 */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-2">
              9. Autonomous Molecular Design and Biological Validation of Allosteric Hsp90 Modulators
            </h3>
            <p className="text-gray-600 mb-4">
              Traditional drug discovery often struggles to target allosteric sites due to their transient nature and unique structural features. We pioneer autonomous molecular design approaches to accelerate the discovery of allosteric modulators:
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-600">
              <li>
                <span className="font-bold">Generative Machine Learning:</span> Use variational autoencoders (VAEs) and generative adversarial networks (GANs) to design novel molecules tailored to specific allosteric pockets.
              </li>
              <li>
                <span className="font-bold">Structure-Based Optimization:</span> Refine generated ligands using docking simulations and free energy calculations to enhance binding affinity and selectivity.
              </li>
              <li>
                <span className="font-bold">Biological Validation:</span> Test computationally designed modulators using biochemical and cell-based assays to confirm efficacy and specificity.
              </li>
            </ul>
            <p className="text-gray-600 italic">
              Applications: These efforts enable the rapid discovery of potent and selective allosteric inhibitors, paving the way for safer and more effective therapeutics.
            </p>
          </div>
        </div>
      </section>

      {/* Methodologies and Tools Section */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-8">Methodologies and Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Structural Bioinformatics */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Structural Bioinformatics</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Homology modeling and template-based prediction: SWISS-MODEL, Modeller</li>
                <li>Cryo-EM data integration: ChimeraX, Phenix</li>
                <li>Protein structure prediction: AlphaFold, ESMFold</li>
              </ul>
            </div>
            {/* Computational Simulations */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Computational Simulations</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Molecular dynamics (MD) simulations: GROMACS, AMBER, NAMD</li>
                <li>Enhanced sampling techniques: Metadynamics, replica exchange, ABF</li>
                <li>Hybrid QM/MM simulations: Gaussian, ORCA</li>
              </ul>
            </div>
            {/* Network Modeling */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Network Modeling</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Graph theory and network analysis: Cytoscape, NetworkX</li>
                <li>Statistical mechanics: MSMs, free energy landscape reconstruction</li>
              </ul>
            </div>
            {/* AI and Machine Learning */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">AI and Machine Learning</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Deep Learning Architectures: CNNs, RNNs, transformers, attention mechanisms</li>
                <li>Graph Neural Networks (GNNs): Modeling protein-ligand interactions</li>
                <li>Generative AI Methods: VAEs, GANs for de novo molecule generation</li>
              </ul>
            </div>
            {/* Software Platforms */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Software Platforms</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>TensorFlow, PyTorch, JAX</li>
                <li>Rosetta, Schrödinger Suite, AutoDock</li>
                <li>VMD, PyMOL, Chimera</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Collaborations and Impact Section */}
      <section className="w-full py-12 bg-gray-100">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Collaborations and Impact</h2>
          <p className="text-gray-600 mb-4">
            Our research thrives on interdisciplinary collaborations with experimental groups specializing in structural biology, biochemistry, and medicinal chemistry. By combining computational predictions with wet-lab experiments, we validate hypotheses and accelerate translational outcomes.
          </p>
          <h3 className="text-2xl font-bold mb-4">Recent Highlights</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>
              <span className="font-bold">Discovery of Novel Allosteric Sites:</span> Identified cryptic pockets in Hsp70 and Hsp90, paving the way for new classes of allosteric inhibitors.
            </li>
            <li>
              <span className="font-bold">Mechanistic Insights into Allostery:</span> Uncovered long-range communication pathways that regulate chaperone activity, informing rational drug design.
            </li>
            <li>
              <span className="font-bold">Development of Selective Inhibitors:</span> Designed inhibitors with superior selectivity profiles, minimizing side effects in preclinical models.
            </li>
          </ul>
          <p className="text-gray-600">
            Professor Verkhivker’s work has been widely recognized for its impact on advancing the understanding of molecular chaperones and their therapeutic potential. His contributions have laid the groundwork for innovative approaches to target-centric drug discovery.
          </p>
        </div>
      </section>

      {/* Join Us Section */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Join Us!</h2>
          <p className="text-gray-600 mb-4">
            We welcome collaborations with researchers interested in advancing the frontiers of translational bioinformatics and computational structural biology of molecular chaperones. Graduate students, postdoctoral fellows, and visiting scientists passionate about tackling challenging problems in chaperone biology and drug discovery are encouraged to reach out.
          </p>
          <p className="text-gray-600">
            For more information, please contact verkhivk@chapman.edu.
          </p>
        </div>
      </section>
    </div>
  );
};

export default MolecularChaperones;