import React, { useState, useEffect } from 'react';
import { ArrowRight, ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react';
import publicationsData from './PubData.json'; 

const Publications = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  // Increase the font size for this page by 2 points when mounted
  useEffect(() => {
    const htmlEl = document.documentElement;
    // Save the current inline font size
    const originalFontSize = htmlEl.style.fontSize;
    // Increase the font size by 2pt (approximation)
    htmlEl.style.fontSize = 'calc(100% + 2pt)';

    return () => {
      // Restore the original font size on unmount
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [expandedAbstracts, setExpandedAbstracts] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInputValue, setPageInputValue] = useState('1');
  const publicationsPerPage = 5;

  // Thumbnails array
  const thumbnails = [
    `${process.env.PUBLIC_URL}/protein1.png`,
    `${process.env.PUBLIC_URL}/protein2.png`,
    `${process.env.PUBLIC_URL}/protein3.png`,
    `${process.env.PUBLIC_URL}/protein4.png`,
    `${process.env.PUBLIC_URL}/protein5.png`,
  ];

  // Sort publications by descending year
  const sortedPublications = publicationsData.sort(
    (a, b) => parseInt(b.year, 10) - parseInt(a.year, 10)
  );

  // Build a dynamic list of unique years
  const uniqueYears = Array.from(
    new Set(sortedPublications.map(pub => pub.year))
  ).sort((a, b) => b - a);

  // Filter the sorted array - enhanced search functionality
  const filteredPublications = sortedPublications.filter((pub) => {
    const matchesSearch = searchQuery === '' || 
      pub.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pub.authors.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pub.journal.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesYear = selectedYear === '' || pub.year.toString() === selectedYear.toString();
    
    return matchesSearch && matchesYear;
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredPublications.length / publicationsPerPage);
  const indexOfLastPublication = currentPage * publicationsPerPage;
  const indexOfFirstPublication = indexOfLastPublication - publicationsPerPage;
  const currentPublications = filteredPublications.slice(
    indexOfFirstPublication,
    indexOfLastPublication
  );

  const toggleAbstract = (index) => {
    setExpandedAbstracts(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handlePageChange = (pageNumber) => {
    const page = Math.min(Math.max(1, pageNumber), totalPages);
    setCurrentPage(page);
    setPageInputValue(page.toString());
    document.getElementById('publications-section').scrollIntoView({ behavior: 'smooth' });
  };

  const handlePageInputChange = (e) => {
    setPageInputValue(e.target.value);
  };

  const handlePageInputBlur = () => {
    let pageNumber = parseInt(pageInputValue);
    
    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    
    setPageInputValue(pageNumber.toString());
    if (pageNumber !== currentPage) {
      handlePageChange(pageNumber);
    }
  };

  const handlePageInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handlePageInputBlur();
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6">Publications</h1>
            <p className="text-xl">
              Explore our contributions to computational biology and drug discovery.
            </p>
          </div>
        </div>
      </section>

      {/* Filters Section */}
      <section className="w-full py-8 bg-gray-100">
        <div className={containerClasses}>
          <div className="flex flex-col lg:flex-row gap-6 justify-center items-center">
            <input
              type="text"
              placeholder="Search by title"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1);
                setPageInputValue('1');
              }}
              className="w-full lg:w-1/3 px-4 py-2 border border-gray-300 rounded-lg"
            />

            <select
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e.target.value);
                setCurrentPage(1);
                setPageInputValue('1');
              }}
              className="w-full lg:w-1/3 px-4 py-2 border border-gray-300 rounded-lg"
            >
              <option value="">Every Year</option>
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>

      {/* Publications Section */}
      <section id="publications-section" className="w-full py-16 bg-gray-50">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-12 text-center">Recent Publications</h2>
          
          {/* Results count */}
          <p className="text-gray-600 mb-6 text-center">
            Showing {indexOfFirstPublication + 1}-{Math.min(indexOfLastPublication, filteredPublications.length)} of {filteredPublications.length} publications
          </p>

          <div className="space-y-8">
            {currentPublications.map((pub, index) => {
              const chosenThumbnail = thumbnails[index % thumbnails.length];
              return (
                <div
                  key={index}
                  className={`bg-white rounded-lg shadow-lg p-6 flex flex-col lg:flex-row gap-6 hover:shadow-xl transition duration-300 ${
                    index % 2 === 0 ? '' : 'lg:flex-row-reverse'
                  }`}
                >
                  <div className="flex-shrink-0 w-full lg:w-1/3">
                    <img
                      src={chosenThumbnail}
                      alt="thumbnail"
                      className="w-full h-48 object-cover rounded-lg"
                    />
                  </div>
                  <div className="w-full lg:w-2/3 relative">
                    <h3 className="text-2xl font-bold mb-3">{pub.title}</h3>
                    <p className="text-gray-600 mb-4">
                      {pub.authors} | {pub.journal} | {pub.year}
                    </p>
                    <button
                      onClick={() => toggleAbstract(index)}
                      className="text-[#4169E1] hover:text-blue-700 font-semibold inline-flex items-center mb-4"
                    >
                      {expandedAbstracts[index] ? 'Hide Abstract' : 'View Abstract'}
                      {expandedAbstracts[index] ? (
                        <ChevronUp className="ml-2" size={20} />
                      ) : (
                        <ChevronDown className="ml-2" size={20} />
                      )}
                    </button>
                    {expandedAbstracts[index] && (
                      <p className="text-gray-700 mb-12">{pub.abstract}</p>
                    )}
                    <a
                      href={pub.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#4169E1] hover:text-blue-700 font-semibold inline-flex items-center absolute bottom-6 right-6"
                    >
                      Read Full Paper <ArrowRight className="ml-2" size={20} />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Compact Pagination Controls */}
          {totalPages > 1 && (
            <div className="mt-12 flex justify-center items-center gap-3">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`p-2 rounded-lg flex items-center ${
                  currentPage === 1
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-blue-600 hover:bg-blue-100'
                }`}
              >
                <ChevronLeft size={18} />
              </button>

              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={pageInputValue}
                  onChange={handlePageInputChange}
                  onBlur={handlePageInputBlur}
                  onKeyDown={handlePageInputKeyDown}
                  min={1}
                  max={totalPages}
                  className="w-16 py-1 px-2 text-center border rounded-lg"
                />
                <span className="text-gray-600">
                  of {totalPages}
                </span>
              </div>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`p-2 rounded-lg flex items-center ${
                  currentPage === totalPages
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-blue-600 hover:bg-blue-100'
                }`}
              >
                <ChevronRight size={18} />
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Publications;