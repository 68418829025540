import React, { useEffect } from 'react';
import { Microscope, Zap, Cpu, FlaskConical, BookOpen, Brain } from 'lucide-react';

const About = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  // Increase the font size for this page by roughly 2 points when mounted
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  // Previous research focuses with icons
  const researchAreas = [
    {
      title: "Computational Structural Virology",
      description:
        "Investigating viral proteins through advanced computational modeling, with focus on SARS-CoV-2 spike proteins and antibody design.",
      icon: <Microscope size={24} className="text-[#4169E1]" />,
    },
    {
      title: "Protein Kinases Research",
      description:
        "Studying protein kinase dynamics and regulation through computational biophysics and network analysis approaches.",
      icon: <Zap size={24} className="text-[#4169E1]" />,
    },
    {
      title: "Generative AI in Biomedicine",
      description:
        "Harnessing AI and machine learning for protein dynamics prediction, drug design, and biomedical research advancement.",
      icon: <Cpu size={24} className="text-[#4169E1]" />,
    },
    {
      title: "Integrative Computational Drug Design",
      description:
        "Combining physics-based simulations, theoretical biophysics, AI, and network science for autonomous allosteric drug discovery.",
      icon: <FlaskConical size={24} className="text-[#4169E1]" />,
    },
    {
      title: "Molecular Chaperones Research",
      description:
        "Investigating Hsp70 and Hsp90 chaperones through computational structural biology and network modeling approaches.",
      icon: <BookOpen size={24} className="text-[#4169E1]" />,
    },
    {
      title: "Allosteric Protein Regulation",
      description:
        "Decoding allosteric mechanisms through computational modeling, network analysis, and AI-driven approaches.",
      icon: <Brain size={24} className="text-[#4169E1]" />,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-6">
              Welcome to the Quantitative Biology and Computational Intelligence Lab
            </h1>
            <p className="text-xl">
              Where cutting-edge computational approaches meet transformative biomedical research. Our lab is dedicated to unraveling the complexities of biological systems through innovative applications of artificial intelligence (AI), machine learning (ML), and advanced molecular modeling techniques. By bridging the gap between computational science and experimental validation, we aim to address fundamental questions in biology and accelerate the discovery of novel therapeutics.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <div className={`${containerClasses} py-12`}>
        {/* Mission Statement */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
          <div className="bg-white rounded-lg shadow-lg p-8 space-y-4">
            <p className="text-lg leading-relaxed text-gray-700">
              At the Verkhivker Lab, we are committed to unraveling the fundamental mechanisms that govern protein function, regulation, and interaction networks. By leveraging advanced computational modeling, AI-driven approaches, and integrative structural biology, we aim to address critical challenges in biomedicine and accelerate the discovery of novel therapeutics.
            </p>
            <p className="text-lg leading-relaxed text-gray-700">
              Our work spans multiple domains, from decoding viral protein and protein kinase regulation to understanding molecular chaperones and allosteric mechanisms. Through collaboration with experimental scientists and clinicians, we translate computational insights into actionable strategies for drug design and disease intervention.
            </p>
          </div>
        </section>

        {/* People Link Section */}
        <section className="mb-16 text-center">
          <a
            href="/people"
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg text-xl transition-colors duration-200"
          >
            Meet Our Team
          </a>
        </section>

        {/* Research Focus */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8">Research Focus</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {researchAreas.map((area, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                <div className="flex items-center gap-3 mb-3">
                  {area.icon}
                  <h3 className="text-xl font-semibold">{area.title}</h3>
                </div>
                <p className="text-gray-700">{area.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Why Allostery */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4">Why Allostery?</h2>
          <div className="bg-white rounded-lg shadow-lg p-8">
            <p className="text-lg leading-relaxed text-gray-700">
              Allostery represents one of the most fundamental mechanisms of biological regulation, yet it remains poorly understood. By targeting allosteric sites rather than active sites, we can develop drugs with higher specificity, reduced side effects, and the ability to modulate previously "undruggable" proteins. Our lab is at the forefront of this exciting field, pioneering new methodologies to harness the power of allostery for drug discovery.
            </p>
          </div>
        </section>

        {/* Why Our Work Matters */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4">Why Our Work Matters</h2>
          <div className="bg-white rounded-lg shadow-lg p-8">
            <p className="text-lg leading-relaxed text-gray-700">
              Understanding the interplay between protein structure, dynamics, and function is key to addressing some of the most pressing challenges in biomedicine. By targeting allosteric sites, viral proteins, and molecular chaperones, we aim to develop therapies with higher specificity, reduced side effects, and the ability to tackle previously "undruggable" targets. Our research has far-reaching implications, from combating infectious diseases like COVID-19 to developing personalized treatments for cancer and neurodegenerative disorders. Our work is supported by grants from the National Institutes of Health (NIH) and Kay Family Foundation and has the potential to transform drug discovery pipelines and improve patient outcomes.
            </p>
          </div>
        </section>

        {/* Join Us */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4">Join Us</h2>
          <div className="bg-white rounded-lg shadow-lg p-8">
            <p className="text-lg leading-relaxed text-gray-700">
              The Verkhivker Lab is a vibrant community of scientists passionate about pushing the boundaries of computational biology and drug discovery. Whether you are a student, postdoc, or collaborator, we invite you to join us in exploring the frontiers of science. Together, we can make discoveries that benefit humanity. For more information about our projects, publications, and opportunities to collaborate, please explore our website or contact us directly.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
