import React, { useEffect } from 'react';
import machineLearning from './Images/ICDD/Picture1.jpeg';
import aiDrugDiscovery from './Images/ICDD/Picture2.jpg';
import drugDiscoveryAcceleration from './Images/ICDD/Picture3.png';

const ICDD = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">Integrative Computational Drug Design (ICDD)</h1>
            <p className="text-xl italic mb-4">Bridging Physics-based Simulations, Theoretical Biophysics, AI, and Network Science for Next-Generation Fully Autonomous Allosteric Drug Discovery</p>
            <p className="text-xl">
              The discovery of novel therapeutics is undergoing a transformative shift, driven by the integration of physics-based simulations, theoretical biophysics, artificial intelligence (AI), and network science. Our lab pioneers ICDD, a fully autonomous framework that combines these disciplines to revolutionize allosteric drug discovery.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <div className="space-y-12">
            {/* Overview Images */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={machineLearning}
                  alt="Machine Learning in Drug Discovery" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={aiDrugDiscovery}
                  alt="AI in Drug Discovery" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Introduction */}
            <div>
              <p className="text-gray-600">
                Allostery—the regulation of protein function through distant sites—offers unparalleled opportunities for designing highly selective and effective drugs with minimal off-target effects. By leveraging advanced computational tools and interdisciplinary approaches, we aim to decode complex biological systems, uncover cryptic allosteric mechanisms, and accelerate the development of next-generation therapeutics.
              </p>
            </div>

            {/* Key Research Themes */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Key Research Themes</h2>
              
              {/* Theme 1 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">1. Physics-based Simulations and Theoretical Biophysics</h3>
                <p className="text-gray-600 mb-4">Physics-based simulations provide a foundation for understanding the dynamic behavior of biomolecules at atomic resolution. We combine molecular dynamics (MD) simulations, free energy calculations, and theoretical biophysics to:</p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Explore conformational landscapes through mapping free energy surfaces</li>
                  <li>Characterize allosteric mechanisms using statistical mechanics</li>
                  <li>Enhance sampling techniques with advanced methods</li>
                </ul>
              </div>

              {/* Large Visualization */}
              <div className="my-12">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <div className="aspect-[16/9] overflow-hidden">
                    <img 
                      src={drugDiscoveryAcceleration}
                      alt="Drug Discovery Acceleration Process" 
                      className="w-full h-full object-contain bg-white"
                    />
                  </div>
                  <p className="text-sm text-gray-600 mt-2 text-center">Accelerating drug discovery through integrated computational approaches</p>
                </div>
              </div>

              {/* Additional Themes */}
              <div className="space-y-8">
                <div>
                  <h3 className="text-2xl font-bold mb-4">2. AI-driven Prediction of Protein Dynamics and Allosteric Landscapes</h3>
                  <p className="text-gray-600">
                    Artificial intelligence is revolutionizing our ability to predict complex biological phenomena. We develop and apply AI methods to predict protein dynamics, map allosteric landscapes, and detect rare functional states.
                  </p>
                </div>

                <div>
                  <h3 className="text-2xl font-bold mb-4">3. Network Science for Decoding Allosteric Communication</h3>
                  <p className="text-gray-600">
                    We use network science to model residue-residue interactions, quantify signal propagation, and identify cryptic binding sites through advanced graph theory and statistical mechanics.
                  </p>
                </div>

                <div>
                  <h3 className="text-2xl font-bold mb-4">4. Generative AI for Fully Autonomous Ligand Design</h3>
                  <p className="text-gray-600">
                    Our lab leverages generative AI to create a fully autonomous workflow for designing novel ligands, using VAEs and GANs for de novo molecule generation and structure-based optimization.
                  </p>
                </div>
              </div>
            </div>

            {/* Technologies Section */}
            <div className="mt-12">
              <h2 className="text-3xl font-bold mb-6">Methodologies and Tools</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Physics-based Simulations</h4>
                  <p className="text-gray-600">GROMACS, AMBER, NAMD, Free energy calculations</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">AI and Machine Learning</h4>
                  <p className="text-gray-600">CNNs, RNNs, GNNs, Transformers</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Generative AI</h4>
                  <p className="text-gray-600">VAEs, GANs, Diffusion Models</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Software Platforms</h4>
                  <p className="text-gray-600">TensorFlow, PyTorch, JAX, Rosetta</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ICDD;