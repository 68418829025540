import React, { useState, useEffect } from 'react';
import {
  Users,
  Brain,
  BookOpen,
  Linkedin,
  Github,
  Mail,
  FileText,
  GraduationCap,
  BookOpenCheck,
  Briefcase,
  BookMarked,
  FlaskConical,
  Telescope,
} from 'lucide-react';

// Import images
import verkhivkerImage from './Headshots/Verkhivker.jpg';
import brandonHeadshot from './Headshots/brandon_headshot.jpg';
import lucasHeadshot from './Headshots/LucasTuranoHeadshot.png';
import bethanyHeadshot from './Headshots/bethanycheungprofile.png';
import VedantHeadshot from './Headshots/VedantHeadshot.png';
import MaxHeadshot from './Headshots/MaxHeadshot.png';
import KevinHeadshot from './Headshots/KevinHeadshot.jpeg';
import NickHeadshot from './Headshots/NickHeadshot.png';
import PriyaHeadshot from './Headshots/PriyaHeadshot.png';
import AnnaHeadshot from './Headshots/AnnaHeadshot.png';
import MarHeadshot from './Headshots/MarinHeadshot.jpeg';
import VisshwamHeadshot from './Headshots/VisshwamHeadshot.jpg';
import GraceGuptaHeadshot from './Headshots/GraceGuptaHeadshot.jpeg';
import SteveHeadshot from './Headshots/SteveHeadshot.jpg';
import DenizHeadshot from './Headshots/DenizHeadshot.jpg';
import MohammedHeadshot from './Headshots/MohammedHeadshot.jpg';
import KeerthiHeadshot from './Headshots/KeerthiHeadshot.jpg';
import RyanHeadshot from './Headshots/RyanHeadshot.jpg';

// Resumes
import AHarnerResume from './Resumes/AHarnerResume.pdf';
import BCheungResume from './Resumes/BCheungResume.pdf';
import BFoleyResume from './Resumes/BFoleyResume.pdf';
import LTuranoResume from './Resumes/LTuranoResume.pdf';
import MLudwickResume from './Resumes/MLudwickResume.pdf';
import MMorrisonResume from './Resumes/MMorrisonResume.pdf';
import VParikhResume from './Resumes/VParikhResume.pdf';

const PersonCard = ({ person }) => {
  const [isEmailOpen, setIsEmailOpen] = useState(false);

  useEffect(() => {
    let timer;
    if (isEmailOpen && person.email) {
      timer = setTimeout(() => {
        setIsEmailOpen(false);
      }, 10000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isEmailOpen, person.email]);

  const renderEducation = () => {
    const allEducation = [
      ...(person.currentEducation || []),
      ...(person.completedEducation || []),
    ].sort((a, b) => b.year - a.year);

    return (
      <div className="space-y-2">
        {allEducation.map((edu, index) => (
          <div key={index} className="flex items-start gap-2">
            <div className="mt-1">
              {edu.status === 'current' ? (
                <BookOpen className="w-4 h-4 text-blue-600" />
              ) : (
                <BookOpenCheck className="w-4 h-4 text-green-600" />
              )}
            </div>
            <div>
              <p className="text-gray-700">
                {edu.degree}
                {edu.major && ` in ${edu.major}`}
              </p>
              <p className="text-sm text-gray-600">
                {edu.institution}, {edu.status === 'current' ? `Expected ${edu.year}` : edu.year}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderRoles = () => (
    <div className="space-y-2">
      {person.roles.map((role, index) => (
        <div key={index} className="flex items-center gap-2">
          <Briefcase className="w-4 h-4 text-gray-600" />
          <p className="text-gray-700">{role}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Left Column - Photo */}
        <div className="w-full md:w-1/3">
          {person.image ? (
            <img
              src={person.image}
              alt={person.name}
              className="w-full h-96 object-cover rounded-lg shadow-md"
            />
          ) : (
            <div className="w-full h-96 bg-gray-200 rounded-lg" />
          )}
        </div>

        {/* Right Column - Details */}
        <div className="w-full md:w-2/3 space-y-6">
          <div>
            <h3 className="text-3xl font-bold text-gray-900">{person.name}</h3>
            {person.title && (
              <p className="text-xl text-gray-600 mt-2">{person.title}</p>
            )}
          </div>

          {/* Contact and Social Links */}
          <div className="flex flex-wrap gap-4">
            {person.email && (
              <div className="relative">
                <button
                  onClick={() => setIsEmailOpen(!isEmailOpen)}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Mail className="w-4 h-4" />
                  Contact
                </button>
                {isEmailOpen && (
                  <div className="absolute top-full mt-2 w-64 p-3 bg-white border rounded-md shadow-lg z-10">
                    <p className="text-sm text-gray-700">{person.email}</p>
                  </div>
                )}
              </div>
            )}

            {person.linkedin && (
              <a
                href={person.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Linkedin className="w-4 h-4" />
                LinkedIn
              </a>
            )}

            {person.github && (
              <a
                href={person.github}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Github className="w-4 h-4" />
                GitHub
              </a>
            )}

            {person.googleScholar && (
              <a
                href={person.googleScholar}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <BookMarked className="w-4 h-4" />
                Google Scholar
              </a>
            )}

            {person.researchGate && (
              <a
                href={person.researchGate}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <FlaskConical className="w-4 h-4" />
                Research Gate
              </a>
            )}

            {person.pubMed && (
              <a
                href={person.pubMed}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Telescope className="w-4 h-4" />
                PubMed
              </a>
            )}

            {person.cv && (
              <a
                href={person.cv}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <FileText className="w-4 h-4" />
                CV
              </a>
            )}
          </div>

          {/* Roles Section */}
          {person.roles && person.roles.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-xl font-semibold text-gray-900">Roles</h4>
              {renderRoles()}
            </div>
          )}

          {/* Education Section */}
          {(person.currentEducation || person.completedEducation) && (
            <div className="space-y-2">
              <h4 className="text-xl font-semibold text-gray-900">Education</h4>
              {renderEducation()}
            </div>
          )}

          {/* Research Interests */}
          {person.researchInterests && person.researchInterests.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-xl font-semibold text-gray-900">Research Interests</h4>
              <div className="flex flex-wrap gap-2">
                {person.researchInterests.map((interest, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                  >
                    {interest}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Activities & Hobbies */}
          {person.hobbies && person.hobbies.length > 0 && (
            <div className="space-y-2">
              <h4 className="text-xl font-semibold text-gray-900">Activities & Hobbies</h4>
              <div className="flex flex-wrap gap-2">
                {person.hobbies.map((hobby, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm"
                  >
                    {hobby}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const People = () => {
  // Increase the font size for this page by roughly 2 points when mounted
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  // Complete team data in the new person-centric format
  const teamMembers = [
    {
      name: "Dr. Gennady Verkhivker",
      title: "Professor of Computational Biosciences and Translational Medicine",
      image: verkhivkerImage,
      email: "verkhivk@chapman.edu",
      linkedin: "https://www.linkedin.com/in/gennady-verkhivker-8266091/",
      roles: ["Principal Investigator", "Research Director"],
      researchInterests: [
        "Computational Structual Virology",
        "Protein Kinases Research",
        "Generative AI in Biomedicine",
        "Intergrative Computational Drug Design",
        "Molecular Chaperones Research",
        "Allosteric Protien Regulation"
      ],
    },
    {
      name: "Maximillian Ludwick",
      image: MaxHeadshot,
      email: "max.ludwick@gmail.com",
      linkedin: "https://www.linkedin.com/in/maxludwick/",
      github: "https://github.com/Madmaxme",
      cv: MLudwickResume,
      roles: ["Computational Core Member"],
      hobbies: ["Fortnite"],
      currentEducation: [
        {
          degree: "BS",
          major: "Data Science",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Lucas Turano",
      image: lucasHeadshot,
      email: "turano@chapman.edu",
      linkedin: "https://www.linkedin.com/in/brandon-foley-a5975b290/",
      github: "https://github.com/LucasTuran0",
      cv: LTuranoResume,
      roles: ["Computational Core Member"],
      hobbies: ["Running", "Surfing", "Rock Climbing"],
      currentEducation: [
        {
          degree: "BS",
          major: "Computer Science",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Brandon Foley",
      image: brandonHeadshot,
      email: "brfoley@chapman.edu",
      linkedin: "https://www.linkedin.com/in/brandon-foley-a5975b290/",
      github: "https://bfoley05.github.io/",
      cv: BFoleyResume,
      roles: ["Computational Core Member"],
      hobbies: ["Hiking", "Traveling", "Working Out", "Reading"],
      currentEducation: [
        {
          degree: "BS",
          major: "Computer Science",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Vedant Parikh",
      image: VedantHeadshot,
      email: "vedpar31@gmail.com",
      github: "https://github.com/vedant-par",
      linkedin: "https://www.linkedin.com/in/vedant-parikh-a3988a184/",
      cv: VParikhResume,
      roles: ["Computational Core Member"],
      hobbies: ["Sports", "Speech", "Debate"],
      currentEducation: [
        {
          institution: "Dougherty Valley High School",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Kevin Huang",
      image: KevinHeadshot,
      email: 'kevhuang@chapman.edu',
      cv: "/cvs/kevin-huang-cv.pdf",
      roles: ["Computational Core Member"],
      currentEducation: [
        {
          degree: "BS",
          major: "Computer Science",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Anna Harner",
      linkedin: "https://www.linkedin.com/in/anna-harner-a85122213/",
      image: AnnaHeadshot,
      cv: AHarnerResume,
      email: 'harner@chapman.edu',
      roles: ["Graduate Researcher"],
      currentEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ],
      completedEducation: [
        {
          degree: "BS",
          major: "Data Science",
          institution: "Chapman University",
          year: 2024,
          status: "completed"
        }
      ]
    },
    {
      name: "Visshwan Desai",
      linkedin: "https://www.linkedin.com/in/visshwam-desai-021533349/",
      image: VisshwamHeadshot,
      cv: "/cvs/visshwan-desai-cv.pdf",
      email: 'visdesai@chapman.edu',
      roles: ["Graduate Researcher"],
      hobbies: ["Violin", "Reading", "Drawing"],
      currentEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ],
      completedEducation: [
        {
          degree: "BS",
          major: "Computer Science",
          institution: "Chapman University",
          year: 2024,
          status: "completed"
        }
      ]
    },
    {
      name: "Steve Agajanian",
      image: SteveHeadshot,
      email: 'agaja102@mail.chapman.edu ',
      roles: ["Graduate Researcher"],
    },
    {
      name: "Deniz Yaşar Öztaş",
      image: DenizHeadshot,
      email: "oztas@chapman.edu",
      linkedin: "https://www.linkedin.com/in/denizyo",
      researchGate: "https://www.researchgate.net/profile/Deniz-Oeztas-2",
      roles: ["Graduate Researcher"],
      completedEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2021,
          status: "completed"
        },
        {
          degree: "MS",
          major: "Bioengineering",
          institution: "Yeditepe University",
          year: 2017,
          status: "completed"
        },
        {
          degree: "BS",
          major: "Genetics & Bioengineering",
          institution: "Yeditepe University",
          year: 2015,
          status: "completed"
        }
      ]
    },
    {
      name: "Mohammad Alshahrani",
      image: MohammedHeadshot,
      email: "alshahrani@chapman.edu",
      linkedin: "https://www.linkedin.com/in/malshahrani/",
      researchGate: "https://www.researchgate.net/profile/Mohammed-Alshahrani-31",
      roles: ["Graduate Researcher"],
      completedEducation: [
        {
          degree: "MS",
          major: "Computer Science",
          institution: "University of Michigan",
          year: 2013,
          status: "completed"
        },
        {
          degree: "BS",
          major: "Computer Science",
          institution: "King Khalid University",
          year: 2009,
          status: "completed"
        }
      ]
    },
    {
      name: "Grace Gupta",
      image: GraceGuptaHeadshot,
      email: "grgupta@chapman.edu",
      linkedin: "https://www.linkedin.com/in/grace-gupta/",
      github: "https://github.com/nayanika-g",
      pubMed: "https://pubmed.ncbi.nlm.nih.gov/?term=Grace+Gupta",
      roles: ["Graduate Researcher"],
      completedEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2023,
          status: "completed"
        },
        {
          degree: "BS",
          major: "Biology",
          institution: "Lewis & Clark College",
          year: 2018,
          status: "completed"
        }
      ]
    },
    {
      name: "Keerthi Krishnan",
      image: KeerthiHeadshot,
      email: "kekrishnan@chapman.edu",
      linkedin: "https://www.linkedin.com/in/keerthi-krishnan-756b75135/",
      github: "https://github.com/kvkrishn",
      roles: ["Graduate Researcher"],
      completedEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2022,
          status: "completed"
        },
        {
          degree: "BS",
          major: "Computer Science & Mathematics",
          institution: "UC Santa Cruz",
          year: 2020,
          status: "completed"
        }
      ]
    },
    {
      name: "Ryan Kassab",
      image: RyanHeadshot,
      email: "rkassab@chapman.edu",
      roles: ["Graduate Researcher"],
      googleScholar: "https://scholar.google.com/citations?view_op=list_works&hl=en&authuser=3&user=hR8wGCEAAAAJ",
      researchGate: "https://www.researchgate.net/profile/Ryan-Kassab",
      currentEducation: [
        {
          degree: "PhD",
          major: "Biomedical Data Science",
          institution: "University of Wisconsin-Madison",
          year: 2027,
          status: "current"
        }
      ],
      completedEducation: [
        {
          degree: "MS",
          major: "Computational & Data Sciences",
          institution: "Chapman University",
          year: 2022,
          status: "completed"
        },
        {
          degree: "BS",
          major: "Computer Science",
          institution: "Chapman University",
          year: 2021,
          status: "completed"
        }
      ]
    },
    {
      name: "Bethany Cheung",
      linkedin: "https://www.linkedin.com/in/bethany-cheung-9348b9281/",
      image: bethanyHeadshot,
      email: "bethanytcheung@gmail.com",
      cv: BCheungResume,
      roles: ["Undergraduate Researcher"],
      hobbies: ["Reading", "Hiking", "Photography"],
      currentEducation: [
        {
          degree: "BS",
          major: "Data Analytics",
          institution: "Chapman University",
          year: 2025,
          status: "current"
        }
      ]
    },
    {
      name: "Nick Tran",
      linkedin: "https://www.linkedin.com/in/nick-tran-120879219/",
      image: NickHeadshot,
      email: 'ttran4@chapman.edu',
      cv: "/cvs/nick-tran-cv.pdf",
      roles: ["Undergraduate Researcher"],
      currentEducation: [
        {
          degree: "BS",
          major: "Biology",
          institution: "Chapman University",
          year: 2026,
          status: "current"
        }
      ]
    },
    {
      name: "Priya Gadhe",
      linkedin: "https://www.linkedin.com/in/priyagadhe/",
      image: PriyaHeadshot,
      email: 'gadhe@chapman.edu',
      cv: "/cvs/priya-gadhe-cv.pdf",
      roles: ["Undergraduate Researcher"],
      currentEducation: [
        {
          degree: "BS",
          major: "Data Science",
          institution: "Chapman University",
          year: 2028,
          status: "current"
        }
      ]
    },
    {
      name: "Marin Morrison",
      linkedin: "https://www.linkedin.com/in/marin-morrison-88a316340/",
      image: MarHeadshot,
      cv: MMorrisonResume,
      email: 'mamorrison@chapman.edu',
      roles: ["Undergraduate Researcher"],
      currentEducation: [
        {
          degree: "BS",
          major: "Biochemistry & Molecular Biology",
          institution: "Chapman University",
          year: 2028,
          status: "current"
        }
      ],
      hobbies: [
        "Dancing",
        "Music",
        "Healthcare",
        "Hiking",
        "Scuba Diving",
        "Paddle Boarding",
        "Traveling",
        "Reading",
        "Instruments"
      ]
    }
  ];

  // Helper function to group members by their primary role
  const groupMembersByRole = () => {
    const pi = teamMembers.filter(person =>
      person.roles.includes("Principal Investigator")
    );
    const core = teamMembers.filter(person =>
      person.roles.includes("Computational Core Member")
    );
    const grad = teamMembers.filter(person =>
      person.roles.includes("Graduate Researcher")
    );
    const undergrad = teamMembers.filter(person =>
      person.roles.includes("Undergraduate Researcher")
    );

    return { pi, core, grad, undergrad };
  };

  // Section header component
  const SectionHeader = ({ icon: Icon, title }) => (
    <div className="mb-8 mt-16 first:mt-0">
      <div className="flex items-center gap-3 mb-4">
        <Icon className="w-8 h-8 text-blue-600" />
        <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
      </div>
    </div>
  );

  const groups = groupMembersByRole();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-4">Our Team</h1>
            <p className="text-xl">Meet the researchers and engineers behind our work</p>
          </div>
        </div>
      </section>

      <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px] py-12">
        {/* Principal Investigator Section */}
        <div>
          <SectionHeader icon={Users} title="Laboratory Director" />
          {groups.pi.map((person, index) => (
            <PersonCard key={index} person={person} />
          ))}
        </div>

        {/* Core Group Section */}
        <div>
          <SectionHeader icon={Brain} title="Computational Core" />
          {groups.core.map((person, index) => (
            <PersonCard key={index} person={person} />
          ))}
        </div>

        {/* Undergraduate Researchers Section */}
        <div>
          <SectionHeader icon={BookOpen} title="Undergraduate Researchers" />
          {groups.undergrad.map((person, index) => (
            <PersonCard key={index} person={person} />
          ))}
        </div>

        {/* Graduate Researchers Section */}
        <div>
          <SectionHeader icon={GraduationCap} title="Graduate Researchers" />
          {groups.grad.map((person, index) => (
            <PersonCard key={index} person={person} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default People;