import React, { useEffect } from 'react';
import allostericImg1 from './Images/AllosetricProteinRegulation/Picture1.jpg';
import allostericImg2 from './Images/AllosetricProteinRegulation/Picture2.jpg';
import allostericImg3 from './Images/AllosetricProteinRegulation/Picture3.jpg';

const AllosetricProteinRegulation = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">Computational Modeling of Allosteric Protein Regulation and Mechanisms</h1>
            <p className="text-xl">
              Allosteric regulation is a fundamental biological process that governs the function of proteins by enabling long-range communication between distinct sites on their structures. Our lab focuses on advancing the understanding of allosteric regulation through cutting-edge computational approaches.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <div className="space-y-12">
            {/* Overview Images */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={allostericImg1}
                  alt="Allosteric protein regulation visualization" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={allostericImg2}
                  alt="Machine learning and protein allostery" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Key Research Themes */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Key Research Themes</h2>
              
              {/* Theme 1 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">1. Development of Novel Biophysical Methods</h3>
                <p className="text-gray-600 mb-4">We develop state-of-the-art biophysical techniques to model and simulate allosteric interactions at atomic resolution. These methods include:</p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Molecular Dynamics (MD) Simulations: Capturing conformational changes and dynamic transitions</li>
                  <li>Enhanced Sampling Techniques: Overcoming timescale limitations to explore rare events</li>
                  <li>Free Energy Calculations: Quantifying thermodynamic properties of allosteric transitions</li>
                </ul>
              </div>

              {/* Large Visualization */}
              <div className="my-12">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <div className="aspect-[16/9] overflow-hidden">
                    <img 
                      src={allostericImg3}
                      alt="Allosteric modulation diagram" 
                      className="w-full h-full object-contain bg-white"
                    />
                  </div>
                  <p className="text-sm text-gray-600 mt-2 text-center">Allosteric modulation and protein regulation mechanisms</p>
                </div>
              </div>

              {/* Additional Themes */}
              <div className="space-y-8">
                <div>
                  <h3 className="text-2xl font-bold mb-4">2. Network-Based Analysis of Allosteric Communication</h3>
                  <p className="text-gray-600">
                    We employ advanced graph theory and network analysis tools to identify "hotspot" residues, map allosteric pathways, and characterize global and local topological features of protein interaction networks.
                  </p>
                </div>

                <div>
                  <h3 className="text-2xl font-bold mb-4">3. Physics-Based Simulation of Allosteric Landscapes</h3>
                  <p className="text-gray-600">
                    Using physics-based simulations, we investigate conformational ensembles, coupling between distant regions, and effects of mutations on allosteric landscapes.
                  </p>
                </div>

                <div>
                  <h3 className="text-2xl font-bold mb-4">4. AI-Driven Tools for Allosteric Site Prediction</h3>
                  <p className="text-gray-600">
                    Leveraging AI and deep learning, we develop innovative tools to predict novel allosteric sites, classify druggable pockets, and integrate multi-omics information.
                  </p>
                </div>
              </div>
            </div>

            {/* Technologies Section */}
            <div className="mt-12">
              <h2 className="text-3xl font-bold mb-6">Applications and Impact</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Biomedical Research</h4>
                  <p className="text-gray-600">Understanding how oncogenic mutations disrupt allosteric regulation in kinases and investigating impact on GPCRs.</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Synthetic Biology</h4>
                  <p className="text-gray-600">Designing synthetic allosteric circuits for precise control over protein function.</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Industrial Collaboration</h4>
                  <p className="text-gray-600">Streamlining development of allosteric drugs with pharmaceutical companies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllosetricProteinRegulation;