import React, { useEffect } from 'react';
import virusPattern from '../Research/Images/ComputationalStructuralVirology/Picture1.jpg';
import coronaVirus from '../Research/Images/ComputationalStructuralVirology/Picture2.jpg';
import proteinStructure from '../Research/Images/ComputationalStructuralVirology/Picture3.png';
import molecularStructure from '../Research/Images/ComputationalStructuralVirology/Picture4.png';

const ComputationalStructuralVirology = () => {
  // Container classes to allow more space on larger screens
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  // Increase the font size for this page by roughly 2 points on mount
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">Computational Structural Virology</h1>
            <p className="text-xl">
              At the intersection of structural biology, computational modeling, and artificial intelligence lies Computational Structural Virology, a dynamic field that seeks to unravel the intricate molecular mechanisms underlying viral infections. Our lab is at the forefront of this research area, leveraging cutting-edge computational techniques to study viral proteins, their interactions with host cells, and the development of therapeutic interventions.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <div className="space-y-12">
            {/* Overview */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Overview</h2>
              <p className="text-gray-600 mb-6">
                A central focus of our work is understanding the SARS-CoV-2 spike protein, a critical component of the virus responsible for host cell entry and immune evasion. Through advanced simulations, AI-driven design, and predictive modeling, we aim to decode the structural and functional properties of this protein, inform antibody and drug design, and predict emerging variants that may evade immunity.
              </p>
              <p className="text-gray-600">
                Our interdisciplinary team combines expertise in structural bioinformatics, machine learning, biophysics, and immunology to address some of the most pressing challenges posed by SARS-CoV-2 and other viral pathogens. By integrating experimental data with computational insights, we strive to accelerate the discovery of effective treatments and preventive measures.
              </p>
            </div>

            {/* Key Research Themes */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Key Research Themes</h2>
              
              {/* Theme 1 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">1. Structural Studies of SARS-CoV-2 Spike Protein</h3>
                <p className="text-gray-600 mb-4">Objective: Elucidate the molecular architecture and conformational dynamics of the SARS-CoV-2 spike protein.</p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Molecular dynamics (MD) simulations to explore the spike protein's open and closed states.</li>
                  <li>Cryo-EM structure analysis to identify key residues involved in receptor binding and fusion.</li>
                  <li>Free energy calculations to quantify the stability of spike conformations and their role in immune evasion.</li>
                </ul>
              </div>

              {/* Theme 2 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">2. Antibody Binding and Design</h3>
                <p className="text-gray-600 mb-4">Objective: Understand how antibodies neutralize the virus and develop next-generation antibodies with enhanced efficacy.</p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Structural docking studies to model antibody-spike interactions at atomic resolution.</li>
                  <li>AI-based optimization of antibody sequences to improve binding affinity and breadth.</li>
                  <li>Epitope mapping to identify conserved regions on the spike protein for broad-spectrum neutralization.</li>
                </ul>
              </div>

              {/* Images Section */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
                <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                  <img 
                    src={virusPattern}
                    alt="Abstract virus pattern visualization" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                  <img 
                    src={coronaVirus}
                    alt="Coronavirus microscopic visualization" 
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              {/* Theme 3 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">3. AI for Allosteric Inhibitor and Antibody Design</h3>
                <p className="text-gray-600 mb-4">Objective: Discover novel allosteric inhibitors and antibodies that disrupt viral function through indirect mechanisms.</p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Machine learning models trained on large datasets of protein-ligand interactions to predict allosteric sites.</li>
                  <li>Graph neural networks to analyze residue-residue communication networks within the spike protein.</li>
                  <li>Virtual screening of chemical libraries to identify small molecules that stabilize inactive spike conformations.</li>
                </ul>
              </div>

              {/* Additional Images */}
              <div className="space-y-8 my-8">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <div className="aspect-[16/9] overflow-hidden">
                    <img 
                      src={proteinStructure}
                      alt="Protein structure classification diagram" 
                      className="w-full h-full object-contain bg-white"
                    />
                  </div>
                  <p className="text-sm text-gray-600 mt-2 text-center">Protein Structure Classification Diagram showing different classes of antibody binding</p>
                </div>
                
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <div className="aspect-[16/9] overflow-hidden">
                    <img 
                      src={molecularStructure}
                      alt="Detailed molecular structure diagram" 
                      className="w-full h-full object-contain bg-white"
                    />
                  </div>
                  <p className="text-sm text-gray-600 mt-2 text-center">Molecular Structure Diagram showing BA.2 and BA.2.86 protein structures and mutations</p>
                </div>
              </div>

              {/* Technologies Section */}
              <div className="mt-12">
                <h2 className="text-3xl font-bold mb-6">Technologies and Tools We Use</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h4 className="font-bold mb-3">Molecular Modeling Software</h4>
                    <p className="text-gray-600">Rosetta, Schrödinger Suite, Amber, GROMACS, PyMOL</p>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h4 className="font-bold mb-3">Machine Learning Frameworks</h4>
                    <p className="text-gray-600">TensorFlow, PyTorch, AlphaFold, DeepMind's tools</p>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h4 className="font-bold mb-3">Bioinformatics Platforms</h4>
                    <p className="text-gray-600">BLAST, Clustal Omega, ICM-Pro, HADDOCK</p>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h4 className="font-bold mb-3">Visualization Tools</h4>
                    <p className="text-gray-600">ChimeraX, VMD, Cytoscape, Matplotlib</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComputationalStructuralVirology;