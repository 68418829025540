import React, { useEffect } from 'react';
import { ChevronRight, Users } from 'lucide-react';
import { Link } from 'react-router-dom';

// Existing images
import cancerCellImage from './Images/cancer_cell.jpg';
import proteinKinasesImage from './Images/protein-and-lipid-kinases.jpg';
import molecularChaperonesImage from './Images/HSP70.jpg';
import bioinformaticsImage from './Images/idk.jpg';

// New images for the updated research areas
import labCoat from './Images/MolecularChaperones/Picture1.png'; // From the MolecularChaperones page
import allostericImg1 from './Images/AllosetricProteinRegulation/Picture1.jpg'; // From the AllosetricProteinRegulation page

const Research = () => {
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  const researchAreas = [
    {
      title: "Computational Structural Virology",
      description: "Investigating viral proteins through advanced computational modeling, with focus on SARS-CoV-2 spike proteins and antibody design.",
      image: cancerCellImage,
      link: "/ComputationalStructuralVirology",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
    {
      title: "Protein Kinases Research",
      description: "Studying protein kinase dynamics and regulation through computational biophysics and network analysis approaches.",
      image: proteinKinasesImage,
      link: "/ProteinKinases",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
    {
      title: "Generative AI in Biomedicine",
      description: "Harnessing AI and machine learning for protein dynamics prediction, drug design, and biomedical research advancement.",
      image: molecularChaperonesImage,
      link: "/GenerativeAIinBiomedicine",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
    {
      title: "Integrative Computational Drug Design",
      description: "Combining physics-based simulations, theoretical biophysics, AI, and network science for autonomous allosteric drug discovery.",
      image: bioinformaticsImage,
      link: "/ICDD",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
    {
      title: "Molecular Chaperones Research",
      description: "Investigating Hsp70 and Hsp90 chaperones through computational structural biology and network modeling approaches.",
      // Updated profile picture from the Molecular Chaperones page
      image: labCoat,
      link: "/MolecularChaperones",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
    {
      title: "Allosteric Protein Regulation",
      description: "Decoding allosteric mechanisms through computational modeling, network analysis, and AI-driven approaches.",
      // Updated profile picture from the Allosteric Protein Regulation page
      image: allostericImg1,
      link: "/AllosetricProteinRegulation",
      icon: <ChevronRight className="text-blue-600" size={24} />,
    },
  ];

  const collaborations = [
    {
      type: "Academic Partnerships",
      description: "Collaborations with leading institutions in structural biology, computational chemistry, and drug discovery.",
    },
    {
      type: "Industry Collaborations",
      description: "Partnerships with pharmaceutical companies for drug development and computational method validation.",
    },
    {
      type: "Research Networks",
      description: "Active participation in international research networks advancing computational biology and drug discovery.",
    },
  ];

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center"> {/* Added text-center */}
            <h1 className="text-5xl font-bold mb-6">Welcome to QBCI Verkhivker Laboratory</h1>
            <p className="text-xl leading-relaxed">
              The challenge of understanding biological systems at the molecular and systems level as well as the integration of computational and experimental approaches for bridging basic and clinical cancer research is what motivates our lab. Our lab integrates computational approaches, artificial intelligence, and structural biology to advance the understanding of protein dynamics and drug discovery. We focus on development of innovative biophysical and computational intelligence approaches and tools for quantitative studies of viral proteins, protein kinases, molecular chaperones, allosteric regulation mechanisms, programmable biology, and allosteric drug discovery.
            </p>
          </div>
        </div>
      </section>

      {/* Research Areas */}
      <section className="w-full py-16 bg-gray-50">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-12 text-center">Current Research Focus</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {researchAreas.map((area, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6"
              >
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-12 h-12 bg-gray-200 rounded-full overflow-hidden">
                    <img
                      src={area.image}
                      alt={area.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-xl font-semibold">{area.title}</h3>
                </div>
                <p className="text-gray-600 mb-4">{area.description}</p>
                <Link
                  to={area.link}
                  className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium"
                >
                  Learn more <ChevronRight className="ml-1" size={16} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Research Network */}
      <section className="w-full py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6">Research Network</h2>
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex items-center gap-3 mb-6">
              <Users className="text-blue-600" size={24} />
              <h3 className="text-xl font-semibold">Collaborative Partners</h3>
            </div>
            <div className="space-y-6">
              {collaborations.map((collaborator, index) => (
                <Link 
                  href={`/collaborations/${collaborator.slug}`} 
                  key={index}
                  className="block border-l-4 border-blue-600 pl-4 hover:bg-gray-100 transition-colors duration-200 p-4"
                >
                  <h4 className="font-semibold text-lg mb-1">{collaborator.type}</h4>
                  <p className="text-gray-600">{collaborator.description}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Research;
