import React, { useEffect } from 'react';
import moleculeDiagram from './Images/ProteinKinases/Picture1.jpg';
import proteinDiagram from './Images/ProteinKinases/Picture2.jpg';


const ProteinKinases = () => {
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-4">
              Computational Biophysics and Quantitative Biology of Protein Kinases
            </h1>
            <p className="text-xl">
              Unraveling kinase dynamics, binding interactions, and allosteric regulation using advanced simulation and AI methods.
            </p>
          </div>
        </div>
      </section>

      {/* Introduction */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Introduction</h2>
          <p className="text-gray-600 mb-4">
            Protein kinases are central to cellular signaling networks, orchestrating a wide array of biological processes through phosphorylation events. Their regulation is often mediated by allosteric mechanisms—dynamic conformational changes that modulate activity in response to ligand binding or post-translational modifications. Understanding these intricate regulatory pathways is critical for advancing our knowledge of cellular function and disease mechanisms.
          </p>
          <p className="text-gray-600">
            Our lab focuses on the computational study and simulation of protein kinase dynamics, binding interactions, and allosteric regulation. By leveraging cutting-edge artificial intelligence (AI) methods, we aim to predict functional landscapes, uncover hidden allosteric states, and design novel inhibitors targeting these elusive conformations. This research integrates advanced computational techniques with experimental validation using nuclear magnetic resonance (NMR) spectroscopy, creating a robust framework for exploring the biophysical underpinnings of kinase allostery.
          </p>
        </div>
      </section>

      {/* (Optional) Visual Gallery */}
      <section className="w-full py-12 bg-gray-100">
        <div className={containerClasses}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="h-64 overflow-hidden rounded-lg shadow-lg">
              <img
                src={moleculeDiagram}
                alt="Molecule diagram visualization" 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-64 overflow-hidden rounded-lg shadow-lg">
              <img
                src={proteinDiagram}
                alt="Protein diagram visualization" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Strategic Research Directions */}
      <section className="w-full py-12 bg-gray-50">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-8">Strategic Research Directions</h2>

          {/* Direction 1 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">1. Advancing AI-Augmented Computer Simulation Approaches</h3>
            <p className="text-gray-600 mb-2">
              <strong>Objective:</strong> Develop and refine machine learning (ML) and deep learning (DL) models to simulate protein conformational dynamics at atomistic resolution.
            </p>
            <p className="text-gray-600 mb-2"><strong>Approach:</strong></p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>Train generative AI models on large datasets of molecular dynamics (MD) simulations, structural ensembles, and biochemical data.</li>
              <li>Incorporate physics-based constraints into AI algorithms to ensure biologically relevant predictions.</li>
              <li>Use reinforcement learning to optimize trajectories of rare but functionally important transitions between kinase states.</li>
            </ul>
            <p className="text-gray-600">
              <strong>Impact:</strong> Enable high-throughput exploration of conformational space, identifying transient and cryptic allosteric states that traditional methods might miss.
            </p>
          </div>

          {/* Direction 2 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">2. Integrating AI Tools with NMR Spectroscopy Studies</h3>
            <p className="text-gray-600 mb-2">
              <strong>Objective:</strong> Validate AI-predicted allosteric landscapes using NMR experiments.
            </p>
            <p className="text-gray-600 mb-2"><strong>Approach:</strong></p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>Perform solution-state NMR to map chemical shifts, relaxation rates, and residual dipolar couplings across different kinase constructs.</li>
              <li>Compare NMR-derived parameters with AI-generated models to refine predictions of allosteric communication pathways.</li>
              <li>Focus on understudied "dark kinases," where limited experimental data exists, to expand the scope of known allosteric mechanisms.</li>
            </ul>
            <p className="text-gray-600">
              <strong>Impact:</strong> Bridge the gap between computational predictions and experimental observations, providing a comprehensive view of kinase allostery.
            </p>
          </div>

          {/* Direction 3 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">3. Developing Perturbation-Based Hierarchical Network Models</h3>
            <p className="text-gray-600 mb-2">
              <strong>Objective:</strong> Create network-based approaches to profile mutational effects on allosteric communications and identify regulatory hotspots.
            </p>
            <p className="text-gray-600 mb-2"><strong>Approach:</strong></p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>Construct residue interaction networks (RINs) from MD simulations and sequence coevolution analyses.</li>
              <li>Apply perturbations (e.g., mutations, ligand binding) to probe how signal propagation changes within the network.</li>
              <li>Use graph theory metrics to detect hubs, bottlenecks, and other key nodes involved in allosteric regulation.</li>
            </ul>
            <p className="text-gray-600">
              <strong>Impact:</strong> Provide insights into how specific residues contribute to allosteric control, guiding rational design of therapeutics.
            </p>
          </div>

          {/* Direction 4 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">4. Optimizing Network Models via NMR Chemical Shift Analysis</h3>
            <p className="text-gray-600 mb-2">
              <strong>Objective:</strong> Enhance network-based approaches by integrating NMR chemical shift perturbations.
            </p>
            <p className="text-gray-600 mb-2"><strong>Approach:</strong></p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>Analyze changes in NMR chemical shifts upon ligand binding or mutation to infer local and global structural rearrangements.</li>
              <li>Correlate these shifts with network model predictions to validate and refine allosteric mechanisms.</li>
              <li>Develop automated pipelines for rapid interpretation of NMR data in the context of network models.</li>
            </ul>
            <p className="text-gray-600">
              <strong>Impact:</strong> Strengthen the predictive power of network models, enabling more accurate characterization of allosteric mechanisms.
            </p>
          </div>

          {/* Direction 5 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">5. Designing an Open-Source Ecosystem: Allostery Research Portal (ARP)</h3>
            <p className="text-gray-600 mb-2">
              <strong>Objective:</strong> Build a collaborative platform for integrative biophysical studies and AI-augmented modeling of allosteric mechanisms.
            </p>
            <p className="text-gray-600 mb-2"><strong>Approach:</strong></p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>Develop user-friendly software tools for simulating kinase dynamics, analyzing NMR data, and visualizing allosteric networks.</li>
              <li>Host curated databases of kinase structures, MD trajectories, and experimental datasets to facilitate community-driven research.</li>
              <li>Implement cloud-based infrastructure for scalable AI computations and real-time collaboration among researchers worldwide.</li>
            </ul>
            <p className="text-gray-600">
              <strong>Impact:</strong> Democratize access to advanced computational and experimental resources, fostering innovation in allosteric drug discovery.
            </p>
          </div>

          {/* Direction 6 */}
          <div className="mb-10">
            <h3 className="text-2xl font-bold mb-2">6. Generative AI Methods for Kinase Inhibitor Design</h3>
            <p className="text-gray-600 mb-2">
              Traditional kinase inhibitors often suffer from poor selectivity due to high conservation of ATP-binding sites. To address this challenge, we focus on designing allosteric inhibitors using generative AI methods. Our approach includes:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-2">
              <li>
                <strong>Fragment-based drug discovery:</strong> Combining fragment docking with MD simulations to identify promising starting points for inhibitor design.
              </li>
              <li>
                <strong>Generative models for molecule design:</strong> Training variational autoencoders (VAEs) and generative adversarial networks (GANs) to create novel molecules targeting allosteric sites.
              </li>
              <li>
                <strong>Structure-based optimization:</strong> Iteratively refining inhibitor designs guided by detailed mechanistic insights from simulations and AI predictions.
              </li>
            </ul>
            <p className="text-gray-600">
              <strong>Applications:</strong> Our goal is to deliver next-generation therapeutics with improved efficacy, selectivity, and safety profiles.
            </p>
          </div>
        </div>
      </section>

      {/* Methodologies and Tools */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-8">Methodologies and Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Computational Techniques */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Computational Techniques</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Molecular Dynamics Simulations: All-atom and coarse-grained simulations</li>
                <li>Enhanced Sampling Methods: Metadynamics, replica exchange, ABF</li>
                <li>QM/MM: Capturing electronic effects during enzymatic reactions</li>
                <li>Machine Learning and AI: Predictive models for kinase–ligand interactions</li>
              </ul>
            </div>
            {/* AI and Generative Methods */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">AI and Generative Methods</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>Deep Learning Architectures: CNNs and RNNs for predicting kinase landscapes</li>
                <li>Graph Neural Networks (GNNs): Modeling protein–ligand interactions</li>
                <li>Generative Models: VAEs and GANs for de novo molecule generation</li>
              </ul>
            </div>
            {/* Software Platforms */}
            <div className="bg-gray-50 p-6 rounded-lg shadow">
              <h4 className="text-xl font-bold mb-3">Software Platforms</h4>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>GROMACS, AMBER, NAMD, CHARMM</li>
                <li>PyMOL, VMD, Chimera</li>
                <li>Schrödinger Suite, AutoDock</li>
                <li>TensorFlow, PyTorch</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Collaborations and Impact & Recent Highlights */}
      <section className="w-full py-12 bg-gray-100">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Collaborations and Impact</h2>
          <p className="text-gray-600 mb-4">
            Our research thrives on interdisciplinary collaborations with experimental groups specializing in biochemistry, structural biology, and medicinal chemistry. By combining computational predictions with wet-lab experiments, we validate hypotheses and accelerate translational outcomes.
          </p>
          <h3 className="text-2xl font-bold mb-4">Recent Highlights</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>
              <strong>Discovery of Novel Allosteric Pockets:</strong> Identified previously unknown binding sites in clinically relevant kinases, paving the way for new inhibitor classes.
            </li>
            <li>
              <strong>Mechanistic Insights into Drug Resistance:</strong> Uncovered mutations that confer resistance to existing kinase inhibitors, guiding rational redesign.
            </li>
            <li>
              <strong>Development of Selective Inhibitors:</strong> Designed inhibitors with superior selectivity profiles, minimizing side effects in preclinical models.
            </li>
          </ul>
          <p className="text-gray-600">
            This research area aims to deliver transformative advances in the field of computational biophysics and quantitative biology of protein kinases. Specific outcomes include:
          </p>
          <ul className="list-disc pl-6 text-gray-600">
            <li>
              <strong>Novel Insights into Allosteric Mechanisms:</strong> Comprehensive maps of allosteric landscapes and regulatory networks for multiple kinase systems.
            </li>
            <li>
              <strong>Breakthroughs in AI Methodology:</strong> Development of state-of-the-art AI tools tailored for studying dynamic biomolecular systems.
            </li>
            <li>
              <strong>Open Science Contributions:</strong> Establishment of the Allostery Research Portal (ARP), promoting transparency, reproducibility, and collaboration in the scientific community.
            </li>
            <li>
              <strong>Translational Applications:</strong> Identification of new allosteric sites and inhibitor candidates, paving the way for innovative therapies against cancer, neurodegenerative diseases, and other conditions linked to kinase dysfunction.
            </li>
          </ul>
        </div>
      </section>

      {/* Join Us Section */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold mb-6">Join Us!</h2>
          <p className="text-gray-600 mb-4">
            We welcome collaborations with researchers interested in advancing the frontiers of computational biophysics and quantitative biology of protein kinases. Graduate students, postdoctoral fellows, and visiting scientists passionate about tackling challenging problems in kinase biology are encouraged to reach out.
          </p>
          <p className="text-gray-600">
            For more information, please contact verkhivk@chapman.edu
          </p>
        </div>
      </section>
    </div>
  );
};

export default ProteinKinases;