import React, { useEffect } from 'react';
import aiPredictions from '../Research/Images/GenerativeAIinBiomedicine/image1.jpg';
import scientificDiagram from '../Research/Images/GenerativeAIinBiomedicine/image2.png';
import robotMeeting from '../Research/Images/GenerativeAIinBiomedicine/image3.jpg';
import machineLearning from '../Research/Images/GenerativeAIinBiomedicine/image5.jpg';

const GenerativeAIinBiomedicine = () => {
  // Container classes to allow more space on larger screens
  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  // Increase the font size for this page by roughly 2 points on mount
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';
    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">
              Computational Intelligence and Generative AI in Biomedicine
            </h1>
            <p className="text-xl">
              The intersection of computational intelligence, AI, and biomedicine is transforming our understanding of complex biological systems and enabling innovative solutions for healthcare challenges.
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <div className="space-y-12">
            {/* Overview Section */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Overview</h2>
              <p className="text-gray-600 mb-6">
                Our lab is at the forefront of applying computational intelligence and generative AI to biomedicine. We leverage advanced machine learning techniques to predict protein dynamics, map allosteric energy landscapes, and design novel therapeutic molecules and antibodies. By integrating experimental data with AI-driven insights, we aim to revolutionize drug discovery and personalized medicine.
              </p>
            </div>

            {/* Images Section: Overview Images */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={aiPredictions}
                  alt="AI Predictions visualization" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="h-64 overflow-hidden rounded-lg shadow-lg">
                <img 
                  src={robotMeeting}
                  alt="Robot meeting visualization" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Key Research Themes */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Key Research Themes</h2>
              
              {/* Theme 1 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">1. AI Methods for Predicting Protein Dynamics</h3>
                <p className="text-gray-600 mb-4">
                  We develop AI-driven tools to model protein conformational changes and predict dynamic behavior.
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Leveraging recurrent neural networks (RNNs) and graph neural networks (GNNs) to capture time-dependent structural changes.</li>
                  <li>Using variational autoencoders (VAEs) to generate diverse conformations and explore rare states.</li>
                  <li>Integrating physics-based simulations with AI for enhanced accuracy and interpretability.</li>
                </ul>
              </div>

              {/* Theme 2 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">2. Mapping Allosteric Energy Landscapes</h3>
                <p className="text-gray-600 mb-4">
                  Our work maps the free energy landscapes of proteins, identifying key allosteric pathways and transitions.
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Reconstructing free energy surfaces from molecular dynamics simulations.</li>
                  <li>Utilizing AI to detect subtle allosteric signals and communication networks.</li>
                  <li>Identifying transient states that may serve as novel drug targets.</li>
                </ul>
              </div>

              {/* Theme 3 */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-4">3. Generative AI for Therapeutic Design</h3>
                <p className="text-gray-600 mb-4">
                  We pioneer generative AI methods to design novel molecules and antibodies, optimizing for binding affinity, selectivity, and synthetic feasibility.
                </p>
                <ul className="list-disc pl-6 text-gray-600 space-y-2">
                  <li>Training generative adversarial networks (GANs) and VAEs for de novo molecule generation.</li>
                  <li>Optimizing molecular structures using reinforcement learning.</li>
                  <li>Designing antibodies with enhanced efficacy through AI-based sequence optimization.</li>
                </ul>
              </div>
            </div>

            {/* Additional Images Section */}
            <div className="space-y-12 my-8">
              <div className="bg-white p-4 rounded-lg shadow-lg">
                <div className="aspect-[16/9] overflow-hidden">
                  <img 
                    src={scientificDiagram}
                    alt="Scientific experiment diagram" 
                    className="w-full h-full object-contain bg-white"
                  />
                </div>
                <p className="text-sm text-gray-600 mt-2 text-center">
                  Diagram illustrating experimental workflows and AI methodologies in biomedicine.
                </p>
              </div>
              
              {/* Last image (machine learning diagram) made way smaller */}
              <div className="bg-white p-4 rounded-lg shadow-lg" style={{ maxWidth: '600px', margin: '0 auto' }}>
                <div className="overflow-hidden">
                  <img 
                    src={machineLearning}
                    alt="Machine learning diagram" 
                    className="w-full h-auto object-contain"
                  />
                </div>
              </div>
            </div>

            {/* Technologies and Tools Section */}
            <div className="mt-12">
              <h2 className="text-3xl font-bold mb-6">Technologies and Tools We Use</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Deep Learning</h4>
                  <p className="text-gray-600">CNNs, RNNs, Transformers, Graph Neural Networks</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Generative AI</h4>
                  <p className="text-gray-600">VAEs, GANs, Diffusion Models</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Software Platforms</h4>
                  <p className="text-gray-600">TensorFlow, PyTorch, JAX, Rosetta</p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h4 className="font-bold mb-3">Simulation Tools</h4>
                  <p className="text-gray-600">GROMACS, AMBER, NAMD, AlphaFold</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default GenerativeAIinBiomedicine;
