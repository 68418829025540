// Footer.js
import React from 'react';
import { Mail, Github, BookOpen } from 'lucide-react';

const Footer = () => {

  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <div className="space-y-2">
              <div className="flex items-center">
                <Mail className="mr-2" size={20} />
                <span>verkhivk@chapman.edu</span>
              </div>
            </div>
          </div>



          {/* Social/Professional Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Links</h3>
            <div className="flex space-x-4">
              <a href="https://github.com/Verkhivker-Research-Group" className="hover:text-gray-300">
                <Github size={24} />
              </a>
              <a 
                href="https://scholar.google.co.uk/citations?view_op=list_works&hl=en&hl=en&user=L6nSSNIAAAAJ"
                className="hover:text-gray-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BookOpen size={24} />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>&copy; {new Date().getFullYear()} QBCI Lab. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;