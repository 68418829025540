import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, FlaskConical, Users, Code, Microscope } from 'lucide-react';
import publications from './PubData.json';
import Chapman from './Images/Chapman.jpg';

const Home = () => {
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';

    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  const sortedPublications = publications
    .sort((a, b) => b.year - a.year)
    .slice(0, 2);

  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  return (
    <div className="w-full">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-16">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl sm:text-5xl font-bold mb-6">
              Quantitative Biology and Computational Intelligence Lab
            </h1>
            <p className="text-lg sm:text-xl mb-8">
              Bridging Computational Innovation and Biological Discovery at Chapman University
            </p>
            <div className="flex justify-center gap-4">
              <Link
                to="/research"
                className="bg-white text-[#4169E1] px-5 py-3 rounded-lg font-semibold 
                       hover:bg-blue-50 transition duration-300 inline-flex items-center"
              >
                Explore Our Work <ArrowRight className="ml-2" size={20} />
              </Link>
              <Link
                to="/Verkhivker"
                className="bg-transparent border-2 border-white text-white px-5 py-3 rounded-lg font-semibold 
                       hover:bg-white/10 transition duration-300"
              >
                Meet Dr. Verkhivker
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Combined Chapman & Schmid Section */}
      <section className="w-full py-12 bg-gradient-to-b from-blue-50 to-white">
        <div className={containerClasses}>
          <div className="flex flex-col lg:flex-row gap-12 items-start">
            {/* Chapman University Card */}
            <div className="lg:w-2/5 bg-white rounded-2xl shadow-lg p-8 hover:shadow-xl transition-shadow">
              <div className="space-y-6">
                <div className="flex items-center gap-3 mb-6">
                  <div className="h-1 w-8 bg-[#4169E1] rounded-full"></div>
                  <h2 className="text-2xl font-bold text-gray-800">Chapman University</h2>
                </div>
                <img 
                  src={Chapman} 
                  alt="Chapman Campus" 
                  className="rounded-lg w-full h-48 object-cover"
                />
                <p className="text-gray-600">
                  Nestled in Orange County, Chapman provides the 
                  foundation for interdisciplinary research bridging computation and biology.
                </p>
                <a 
                  href="https://www.chapman.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center font-semibold text-[#4169E1] hover:text-blue-700"
                >
                  Explore Chapman <ArrowRight className="ml-2" size={18} />
                </a>
              </div>
            </div>

            {/* Schmid College Video Section */}
            <div className="lg:w-3/5 flex-1">
              <div className="flex items-center gap-3 mb-6">
                <div className="h-1 w-8 bg-[#4169E1] rounded-full"></div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wide">Home to</h3>
                  <h2 className="text-2xl font-bold text-gray-800">Schmid College of Science and Technology</h2>
                </div>
              </div>
              
              <div className="relative aspect-video rounded-xl shadow-lg overflow-hidden border-4 border-white">
                <iframe
                  src="https://www.youtube.com/embed/PIuSc7mOnzc"
                  title="SCST Overview"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Research Overview */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold text-center mb-12">Our Research Vision</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300">
              <Microscope className="w-12 h-12 text-[#4169E1] mb-4" />
              <h3 className="text-xl font-semibold mb-3">Accelerating Drug Discovery</h3>
              <p className="text-gray-600">
                Using computer models and AI to speed up development of new therapies.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300">
              <Code className="w-12 h-12 text-[#4169E1] mb-4" />
              <h3 className="text-xl font-semibold mb-3">Decoding Biology</h3>
              <p className="text-gray-600">
                Creating computational tools to understand complex biological systems.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300">
              <FlaskConical className="w-12 h-12 text-[#4169E1] mb-4" />
              <h3 className="text-xl font-semibold mb-3">Molecular Simulations</h3>
              <p className="text-gray-600">
                Simulating molecular interactions to uncover disease mechanisms.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Recent Highlights */}
      <section className="w-full py-12 bg-gray-50">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold text-center mb-12">Recent Highlights</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold mb-4">Featured Publications</h3>
              <div className="border-l-4 border-[#4169E1] pl-4 space-y-4">
                {sortedPublications.map((pub, index) => (
                  <div key={index}>
                    <p className="text-sm text-gray-500">{pub.year}</p>
                    <h4 className="text-lg font-medium">{pub.title}</h4>
                    <a
                      href={pub.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#4169E1] hover:text-blue-700 inline-flex items-center mt-2"
                    >
                      Read Summary <ArrowRight className="ml-1" size={16} />
                    </a>
                  </div>
                ))}
                <Link
                  to="/publications"
                  className="text-[#4169E1] hover:text-blue-700 font-medium inline-block mt-4"
                >
                  View All Publications →
                </Link>
              </div>
            </div>
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold mb-4">Lab News</h3>
              <div className="bg-white p-6 rounded-lg shadow-lg space-y-6">
                <div>
                  <p className="text-sm text-gray-500">New Initiative</p>
                  <h4 className="text-lg font-medium">NIH Funding Awarded</h4>
                  <p className="text-gray-600">
                    Supporting our innovative approaches to cancer drug discovery
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Collaboration</p>
                  <h4 className="text-lg font-medium">Global Partnerships</h4>
                  <p className="text-gray-600">
                    Working with international teams to advance computational biology
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="w-full py-12">
        <div className={containerClasses}>
          <h2 className="text-3xl font-bold text-center mb-6">Our Research Family</h2>
          <p className="text-xl text-gray-600 text-center mb-8 max-w-3xl mx-auto">
            A diverse team of passionate researchers, students, and collaborators driving innovation 
            in computational biology and therapeutic development.
          </p>
          <div className="flex justify-center">
            <Link
              to="/people"
              className="bg-gradient-to-r from-blue-700 to-blue-500 text-white px-6 py-3 rounded-lg font-semibold 
                       hover:bg-blue-700 transition duration-300 inline-flex items-center"
            >
              Meet the Team <Users className="ml-2" size={20} />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;