// Contact.js
import React, { useEffect } from 'react';
import { Mail, MapPin, Building, Clock, GraduationCap, Users } from 'lucide-react';

const Contact = () => {
  // Apply the same font-size adjustment as in Home.js
  useEffect(() => {
    const htmlEl = document.documentElement;
    const originalFontSize = htmlEl.style.fontSize;
    htmlEl.style.fontSize = 'calc(100% + 2pt)';

    return () => {
      htmlEl.style.fontSize = originalFontSize;
    };
  }, []);

  const containerClasses = 'mx-auto px-4 sm:px-6 lg:px-8 max-w-[1920px]';

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="w-full bg-gradient-to-r from-blue-700 to-blue-500 text-white py-20">
        <div className={containerClasses}>
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-6">Contact Information</h1>
            <p className="text-xl">
              Quantitative Biology and Computational Intelligence Lab
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <div className={`${containerClasses} py-12`}>
        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Information */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-bold mb-6">Dr. Gennady Verkhivker</h2>
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <Building className="text-blue-600 mt-1" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Academic Affiliation</h3>
                  <p className="text-gray-700">
                    Professor of Computational Biology and Computer Science
                    <br />
                    Schmid College of Science and Technology
                    <br />
                    Chapman University
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <MapPin className="text-blue-600 mt-1" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Office Location</h3>
                  <p className="text-gray-700">
                    Keck Center for Science and Engineering
                    <br />
                    Chapman University
                    <br />
                    Orange, CA 92866
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <Mail className="text-blue-600 mt-1" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Email</h3>
                  <a 
                    href="mailto:verkhivk@chapman.edu"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    verkhivk@chapman.edu
                  </a>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <Clock className="text-blue-600 mt-1" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Office Hours</h3>
                  <p className="text-gray-700">
                    Please email to schedule an appointment
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="space-y-8">
            {/* Research Opportunities */}
            <div className="bg-white rounded-lg shadow-lg p-8">
              <div className="flex items-center mb-4">
                <GraduationCap className="text-blue-600 mr-3" size={24} />
                <h3 className="text-xl font-semibold">Research Opportunities</h3>
              </div>
              <p className="text-gray-700 mb-4">
                We welcome inquiries from prospective graduate students and postdoctoral researchers interested in:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Computational Cancer Biology</li>
                <li>Bioinformatics and Systems Biology</li>
                <li>Drug Discovery and Development</li>
                <li>Machine Learning in Healthcare</li>
              </ul>
            </div>

            {/* Collaboration */}
            <div className="bg-white rounded-lg shadow-lg p-8">
              <div className="flex items-center mb-4">
                <Users className="text-blue-600 mr-3" size={24} />
                <h3 className="text-xl font-semibold">Collaboration</h3>
              </div>
              <p className="text-gray-700">
                Our lab actively collaborates with research groups worldwide. If you're interested in potential collaboration opportunities in computational biology, cancer research, or drug discovery, please reach out via email with a brief description of your research interests.
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Message */}
        <div className="mt-8 bg-white rounded-lg shadow-lg p-8 text-center">
          <p className="text-gray-600">
            For general inquiries, research opportunities, or collaboration interests,
            please email with a brief description of your background and interests.
            We aim to respond to all inquiries within 2-3 business days.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;