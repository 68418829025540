// Layout.js
import React from 'react';
import Navbar from './components/Layout/NavBar';
import Footer from './components/Layout/Footer';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col w-full">
      <Navbar />
      <main className="flex-grow w-full">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;