// App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from './components/Home';
import Verkhivker from './components/Verkhivker';

import Research from './components/Research/Research';
import ComputationalStructuralVirology from './components/Research/ComputationalStructuralVirology';
import ProteinKinases from './components/Research/ProteinKinases';
import MolecularChaperones from './components/Research/MolecularChaperones';
import GenerativeAIinBiomedicine from './components/Research/GenerativeAIinBiomedicine';
import ICDD from './components/Research/ICDD';

//allosteric is spelt wrong but ion feel like going and changing everything
import AllosetricProteinRegulation from './components/Research/AllosetricProteinRegulation';


import People from './components/People';
import About from './components/About';
import Publications from './components/Publications';
import Contact from './components/Contact';



function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} /> 

          {/* RESEARCH STUFF */}
          <Route path="/Research" element={<Research />} /> 
          <Route path="/ComputationalStructuralVirology" element={<ComputationalStructuralVirology />} />
          <Route path="/ProteinKinases" element={<ProteinKinases />} />
          <Route path="/MolecularChaperones" element={<MolecularChaperones />} />
          <Route path="/GenerativeAIinBiomedicine" element={<GenerativeAIinBiomedicine />} />
          <Route path="/ICDD" element={<ICDD />} />
          <Route path="/AllosetricProteinRegulation" element={<AllosetricProteinRegulation />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Verkhivker" element={<Verkhivker />} /> 
          <Route path="/About" element={<About />} />
          <Route path="/People" element={<People />} />
          <Route path="/publications" element={<Publications />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
